import { FormType } from '@/constant/form';
import unitNumber from '@/utils/unitNumber';

import {
  IS_ANOMALOUS,
} from '../constant';

export const TABLECOLUMN = (cols = []) => {
  const attr = cols.map((item) => ({
    label: `批属性-${item.fieldName}`,
    prop: `batch_${item.humpFieldCode}`,
    exportProp: item.humpFieldCode,
    width: 180,
    formatter: (row) => row[item.humpFieldCode],
  }));
  return [{
    label: '序号',
    type: 'index',
    prop: 'index',
    width: 50,
  }, {
    label: '货品编码',
    prop: 'goodsCode',
    minWidth: 180,
  }, {
    label: '货主货品编码',
    prop: 'shipperGoodsCode',
    minWidth: 180,
  }, {
    label: '商品名称',
    prop: 'goodsName',
    minWidth: 120,
  }, {
    label: '货主',
    prop: 'shipperName',
    minWidth: 100,
  }, {
    label: '温层',
    prop: 'goodsTemperatureLayerName',
    minWidth: 80,
  }, {
    label: '默认单位',
    prop: 'defaultUnit',
    minWidth: 80,
  }, {
    label: '换算率',
    prop: 'conversionRate',
    minWidth: 80,
  }, {
    label: '批次号',
    prop: 'batchNo',
    minWidth: 150,
  },
  ...attr,
  {
    label: '期初库存（默认单位）',
    prop: 'defaultUnitBeginStock',
    minWidth: 160,
  }, {
    label: '期初总重(吨)',
    prop: 'beginWeight',
    minWidth: 110,
  }, {
    label: '入库量',
    prop: 'inBoundNum',
    minWidth: 100,
    formatter: (row) => (row.inBoundNum
      ? unitNumber.calculate({ ...row, smallUnitNumber: row.inBoundNum }).txt : row.inBoundNum),

  }, {
    label: '入库量(默认单位)',
    prop: 'defaultUnitInBoundNum',
    minWidth: 160,
  }, {
    label: '入库总重(吨)',
    prop: 'inBoundWeight',
    minWidth: 110,
  }, {
    label: '出库量',
    prop: 'outBoundNum',
    minWidth: 100,
    formatter: (row) => (row.outBoundNum
      ? unitNumber.calculate({ ...row, smallUnitNumber: row.outBoundNum }).txt : row.outBoundNum),

  }, {
    label: '出库量(默认单位)',
    prop: 'defaultUnitOutBoundNum',
    minWidth: 160,
  }, {
    label: '出库总重(吨)',
    prop: 'outBoundWeight',
    minWidth: 110,
  }, {
    label: '调整量',
    prop: 'adjustNum',
    minWidth: 100,
    formatter: (row) => (row.adjustNum
      ? unitNumber.calculate({ ...row, smallUnitNumber: row.adjustNum }).txt : row.adjustNum),

  }, {
    label: '调整量(默认单位)',
    prop: 'defaultUnitAdjustNum',
    minWidth: 160,
  }, {
    label: '调整重量(吨)',
    prop: 'adjustWeight',
    minWidth: 110,
  }, {
    label: '期末库存（默认单位）',
    prop: 'defaultUnitEndStock',
    minWidth: 160,
  }, {
    label: '期末总重(吨)',
    prop: 'endWeight',
    minWidth: 110,
  }, {
    label: '是否异常',
    prop: 'abnormal',
    minWidth: 100,
  }, {
    label: '周转率',
    prop: 'turnoverRate',
    minWidth: 80,
  }, {
    label: '周转天数',
    prop: 'turnoverDay',
    minWidth: 80,

  }, {
    label: '库龄<60天库存量(吨)',
    prop: 'stockAgeOne',
    minWidth: 100,

  }, {
    label: '60≤库龄<90天库存量(吨)',
    prop: 'stockAgeTwo',
    minWidth: 110,
  },
  {
    label: '库龄≥90天库存量(吨)',
    prop: 'stockAgeThree',
    minWidth: 100,
  },
  ];
};
export const serchFields = (zoneTemperature) => [{
  label: '日期范围',
  prop: 'dateRange',
  component: FormType.DATE_PICKER,
  componentAttrs: {
    type: 'daterange',
    startPlaceholder: '开始日期',
    endPlaceholder: '结束日期',
  },
}, {
  label: '批次',
  prop: 'batchNo',
  component: FormType.INPUT,
}, {
  label: '货品编码',
  prop: 'goodsCode',
  component: FormType.INPUT,
}, {
  label: '商品名称',
  prop: 'goodsName',
  component: FormType.INPUT,
}, {
  label: '货主',
  prop: 'shipperId',
}, {
  label: '是否异常',
  prop: 'abnormal',
  component: FormType.SELECT,
  options: IS_ANOMALOUS,
}, {
  label: '温层',
  prop: 'goodsTemperatureLayer',
  component: FormType.SELECT,
  options: zoneTemperature,
},
];
