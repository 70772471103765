import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:按货品维度进销存报表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15289
 */
export function stockHistoryReportByGoods(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock_history_report/goods_page', data, {
    params,
  });
}

/**
 * @description:按货品维度实时进销存报表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18533
 */
export function recentHistoryReportByGoods(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/recent_stock_history_report/goods_page', data, {
    params,
  });
}
/**
 * @description:按货品维度进销存导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15293
 */
export function stockHistoryGoodsExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/stock_history_report/goods/export', data, { fileName: '货品维度进销存表', extension: 'xlsx' });
}
/**
 * @description:按货品维度实时进销存导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18537
 */
export function recentHistoryGoodsExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/recent_stock_history_report/goods/export', data, { fileName: '货品维度进销存表', extension: 'xlsx' });
}

/**
 * @description:按货品批次维度进销存报表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15291
 */
export function stockHistoryReportByBatch(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock_history_report/page', data, {
    params,
  });
}
/**
 * @description:按货品批次实时维度进销存报表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18535
 */
export function recentHistoryReportByBatch(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/recent_stock_history_report/page', data, {
    params,
  });
}
/**
 * @description:按货品批次纬度进销存导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15295
 */
export function stockHistoryBatchExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/stock_history_report/export', data, { fileName: '批次维度进销存表', extension: 'xlsx' });
}
/**
 * @description:按货品批次纬度实时进销存导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18539
 */
export function recentHistoryBatchExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/recent_stock_history_report/export', data, { fileName: '批次维度进销存表', extension: 'xlsx' });
}
