<template>
  <pl-block>
    <PlForm
      v-model="searchQuery"
      mode="search"
      :fields="serchFields(zoneTemperature)"
      @submit="handleQuery"
      @reset="handleQuery"
    >
      <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="searchQuery.shipperId"
        />
      </template>
      <template #dateRange>
        <el-date-picker
          v-model="searchQuery.dateRange"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="x"
          :disabled-date="disabledDate"
          @calendar-change="calendarChange"
          @focus="handleDateFocus"
        />
      </template>
    </PlForm>
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="tableCol"
      :checked-columns="defaultCheckedCol"
      show-summary
      :show-table-setting="true"
      :summary-method="handleGetSummaries"
      @update:pagination="handlePaginationChange"
      @columnsChange="handleColumnsProp"
    >
      <template #tableHeaderRight>
        <nh-button
          :loading="loading.handleExport"
          :track="{
            trackName: '报表中心/进销存报表/按批次tab页/[导出]'
          }"
          @click="handleExport"
        >
          导出
        </nh-button>
      </template>
      <template #abnormal="{row}">
        <AbnormalTag :abnormal-text="row.abnormal" />
      </template>
    </PlTable>
  </pl-block>
</template>
<script>
import tableSummary from '@/utils/tableSummary';

import loadingMixin from '@thales/loading';
import { SearchType } from '@/constant/form';
import moment from 'moment';
import {
  TABLECOLUMN, serchFields,
} from './fileds';
import { customerShowList } from '../../location-batch-query/api';
import {
  stockHistoryReportByBatch, stockHistoryBatchExport,
  recentHistoryReportByBatch, recentHistoryBatchExport,
} from '../api';
import {
  IS_ANOMALOUS_ENUM,
} from '../constant';
import AbnormalTag from '../components/AbnormalTag.vue';
import summaryConfig from './config';
import isToday from '../unit';

export default {
  name: 'ByGoods',
  components: {
    AbnormalTag,
  },
  mixins: [loadingMixin],
  data() {
    const yesterday = moment().subtract(1, 'days');
    return {
      selectToday: null,
      serchFields,
      TABLECOLUMN,
      SearchType,
      IS_ANOMALOUS_ENUM,
      tableData: [],
      searchQuery: {
        shipperId: null,
        dateRange: [yesterday.startOf('day').format('x'), yesterday.endOf('day').format('x')],
      },
      loading: {
        getTableData: false,
        handleExport: false,
      },
      batchField: [],
      selectCol: [],
      defaultCheckedCol: [],
    };
  },
  computed: {
    zoneTemperature() {
      return this.$dictionaryFiledMap.ZONE_TEMPERATURE_LAYER_CODE || [];
    },
    tableCol() {
      return TABLECOLUMN(this.batchField);
    },
  },
  created() {
    this.getTableData();
    this.getBatchField();
    this.defaultCheckedCol = (TABLECOLUMN() || []).map((item) => item.prop || item.type);
  },
  methods: {
    async getBatchField() {
      this.batchField = await customerShowList();
    },
    // 选择的字段
    handleColumnsProp(col) {
      this.selectCol = col;
    },
    calendarChange(e) {
      // 是否选择当天
      this.selectToday = e[0].getTime();
    },
    handleDateFocus() {
      this.selectToday = null;
    },
    disabledDate(date) {
      const today = moment();
      if (this.selectToday) {
        const selectToday = today.diff(Number(this.selectToday), 'day') === 0;
        if (selectToday) {
          return !moment(today).isSame(date.getTime(), 'day');
        }
        return moment(date.getTime()).isAfter(today) || moment(today).isSame(date.getTime(), 'day');
      }
      return moment(date.getTime()).isAfter(today);
    },
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const params = this.buildParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      let resp = {};
      const flag = isToday(params.dateStart, params.dateEnd);
      if (flag) {
        resp = await recentHistoryReportByBatch(pagination, params);
      } else {
        resp = await stockHistoryReportByBatch(pagination, params);
      }
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    handleGetSummaries({ columns, data }) {
      const config = summaryConfig;
      const sums = [];
      columns.forEach((column, index) => {
        sums[index] = tableSummary({ column, data, config });
      });
      return sums;
    },
    handlePaginationChange() {
      this.getTableData();
    },
    buildParams() {
      const [dateStart, dateEnd] = this.searchQuery.dateRange || [];
      const params = {
        dateStart,
        dateEnd,
        ...this.searchQuery,
      };
      return params;
    },
    async handleExport() {
      const data = this.buildParams();
      const flag = isToday(data.dateStart, data.dateEnd);
      // 导出时以选择的字段为准
      const exportCol = this.selectCol.length > 0 ? this.selectCol : this.defaultCheckedCol;
      const exportFields = exportCol.reduce((prev, curr) => {
        if (curr !== 'selection' && curr !== 'index' && curr !== 'action') {
          const findField = this.tableCol.find((item) => item.prop === curr) || {};
          return [...prev, {
            fieldCode: findField.exportProp || findField.prop,
            fieldName: findField.label,
          }];
        }
        return prev;
      }, []);
      if (flag) {
        await recentHistoryBatchExport({ ...data, exportFields });
      } else {
        await stockHistoryBatchExport({ ...data, exportFields });
      }
    },
  },
};
</script>
