import { formatKeyValueObject } from '@/utils/base';

export const IS_ANOMALOUS = [
  {
    value: true,
    label: '异常',
  },
  {
    value: false,
    label: '无异常',
  },
];
export const IS_ANOMALOUS_ENUM = formatKeyValueObject(IS_ANOMALOUS);

export const ACTIVE_NAME = {
  BY_BATCH_NO: 'byBatchNo',
  BY_GOODS: 'byGoods',
};
