<template>
  <el-tag
    :type="isAbnormal ? 'danger' : 'success'"
  >
    {{ statusText }}
  </el-tag>
</template>

<script>

const ABNORMAL_TEXT = '异常';
const NORMAL_TEXT = '无异常';
export default {
  props: {
    abnormalText: {
      type: String,
      default: '',
    },
  },
  computed: {
    isAbnormal() {
      return !!this.abnormalText;
    },
    statusText() {
      return this.isAbnormal ? ABNORMAL_TEXT : NORMAL_TEXT;
    },
  },
};
</script>
