<template>
  <pl-block>
    <el-tabs v-model="activeName" @tab-click="handleClickTabs">
      <el-tab-pane label="按货品" :name="ACTIVE_NAME.BY_BATCH_NO">
        <ByGoods />
      </el-tab-pane>
      <el-tab-pane label="按批次" :name="ACTIVE_NAME.BY_GOODS">
        <ByBatchNo />
      </el-tab-pane>
    </el-tabs>
  </pl-block>
</template>
<script>
import ByBatchNo from './by-batchNo/idnex.vue';
import ByGoods from './by-goods/index.vue';
import { ACTIVE_NAME } from './constant';

export default {
  name: 'SalesAndInventory',
  components: {
    ByBatchNo,
    ByGoods,
  },
  data() {
    return {
      ACTIVE_NAME,
      activeName: ACTIVE_NAME.BY_BATCH_NO,
      path: '',
      name: '',
      startTime: null,
    };
  },
  created() {
    this.startTime = Date.now();
    this.path = this.$route.path;
    this.name = this.activeName === ACTIVE_NAME.BY_BATCH_NO ? '按批次tab页' : '按货品tab页';
  },
  beforeUnmount() {
    this.handelQuery();
  },

  methods: {
    handelQuery() {
      window.YlTrack.report({
        trackType: 'view',
        duration_time: (Date.now() - this.startTime) / 1000,
        trackName: this.path,
        trackNameZh: `报表中心/进销存报表/${this.name}`,
      });
    },
    handleClickTabs() {
      this.handelQuery();
      this.name = this.activeName === ACTIVE_NAME.BY_BATCH_NO ? '按批次tab页' : '按货品tab页';
    },
  },
};
</script>
